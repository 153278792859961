import { Paginator } from '@components/paginator'
import * as React from 'react'
import { LayoutView } from '@modules/blog'
import { useSelector } from 'react-redux'
import { paginatorSelector } from '@store/slices/website-slice'

interface Props {
  children: (layoutView: LayoutView) => React.ReactNode
  perPage?: string
  onPageChange: (selectedItem: { selected: number }) => void
  filters: React.ReactNode
}

export const ItemLayout = ({ onPageChange, children, perPage, filters }: Props) => {
  const [layoutView, setLayoutView] = React.useState<LayoutView>('grid')

  const paginator = useSelector(paginatorSelector)

  const pageCount = Math.ceil(paginator.count / parseInt(perPage || '16', 10))
  const handleLayoutChange = (layout: LayoutView) => () => setLayoutView(layout)

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between mb-3">
        <div className="col-lg-auto col-2">
          {layoutView === 'list' ? (
            <i className="uil-apps font-size-28 text-success cursor-pointer" onClick={handleLayoutChange('grid')} />
          ) : (
            <i
              className="uil-list-ul font-size-28 text-success cursor-pointer d-block d-lg-inline"
              onClick={handleLayoutChange('list')}
            />
          )}
        </div>
        <div className="d-flex align-items-center">{filters}</div>
      </div>

      {children(layoutView)}
      {pageCount > 1 && (
        <Paginator
          onPageChange={onPageChange}
          pageCount={pageCount}
          className="justify-content-lg-end w-100 justify-content-center mt-3 mt-lg-0"
        />
      )}
    </div>
  )
}
