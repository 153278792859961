import * as React from 'react'
import { HomeWelcomeSection } from '@modules/home/home-welcome-section'
import { HomeProductsList } from '@modules/home/home-products-list'
import { HomeHintSection } from '@modules/home/home-hint-section'
import { HomeBlogSection } from '@modules/home/home-blog-section'
import { HomeSeedsGallerySection } from '@modules/home/home-seeds-gallery-section'
import { PageLayout } from '@components/page-layout'
import { fakeProducts, SidebarRecommendedList } from '@components/sidebar/sidebar-recommended-list'
import { SidebarContact } from '@components/sidebar/sidebar-contact'
import { useGetBlock } from '@hooks/use-get-block'

export const HomeView: React.FC = () => {
  const block = useGetBlock('boks-na-gorze-strony-glownej')

  if (!block) {
    return null
  }

  return (
    <PageLayout>
      <div className="d-flex flex-column gap-lg-5 gap-3">
        <HomeWelcomeSection />
        <HomeProductsList />
        <HomeHintSection
          name="Krzysztof Baran"
          avatar="recommended-item.png"
          description="Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi. Oferujemy nasiona traw, kwiatów, drzew ozdobnych i owocowych, kłącza, bulwy oraz cebule. Prowadzimy sprzedaż materiałów szkółkarskich oraz artykułów pokrewnych..."
          text="Jakie rośliny są najlepsze do sadzenia w domu?"
        />
        <div className="d-block d-lg-none">
          <SidebarRecommendedList />
          <div className="sidebar__contact-card my-5">
            <SidebarContact />
          </div>
          <img className="mb-4" src={require('@assets/images/logo-isf.svg')} alt="" />
        </div>

        <HomeBlogSection articles={blogArticles} />
        <HomeSeedsGallerySection images={images} />
        <hr />
        <div
          className="pb-5"
          dangerouslySetInnerHTML={{
            __html: block.content,
          }}
        />
      </div>
    </PageLayout>
  )
}

export const recommendedItems = {
  news: fakeProducts,
  bestsellers: fakeProducts,
  most_popular: fakeProducts,
}

export const blogArticles = [
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
  {
    title: 'Jakie rośliny najlepiej sadzić w ogrodzie?',
    text: 'Inspiracją do stworzenia tego wyjątkowego sklepu internetowego było zamiłowanie do naturalnego piękna, które wyrasta z ziemi.',
    image: 'recommended-item.png',
  },
]

const images = [
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
  'recommended-item.png',
]
