import * as React from 'react'
import { Input } from '@components/controls/input'
import { PageLayout } from '@components/page-layout'
import { Button } from '@components/button'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-api-request'
import { sendContactMessage } from '@api/contact'
import { Alert } from 'react-bootstrap'

interface FormInputs {
  name: string
  phone: string
  email: string
  subject: string
  message: string
}

export const ContactView = (): JSX.Element => {
  const methods = useForm<FormInputs>()

  const { action: onSubmit, isLoading } = useFormRequest(async (data: FormInputs) => {
    await sendContactMessage(data)
  }, methods.setError)

  return (
    <PageLayout>
      <div className="p-3 w-100">
        <p className="text-success fw-bold">Kontakt</p>
        <div className="d-md-flex d-block mx-5">
          <div className="ms-n5 col-12 col-lg-6">
            <div className="d-flex align-items-center gap-4 py-3">
              <i className="uil-envelope text-success font-size-30" />
              <a href="mailto:kontakt@dobrenasiona.pl" className="text-warning">
                kontakt@dobrenasiona.pl
              </a>
            </div>

            <div className="d-flex align-items-center gap-3 py-3">
              <i className="uil-phone text-success font-size-30" />
              <a href="tel:+48602353022">(+48) 602 353 022</a>
            </div>

            <div className="d-flex align-items-center gap-3">
              <img src={require('@assets/images/icons/piorin.svg')} alt="" height={65} width={30} />
              <div>Nr PIORiN: PL-24/66/7059</div>
            </div>
          </div>
          <div className="d-flex ms-n5 align-items-start gap-3 pb-3 py-3">
            <i className="uil-map-marker text-success font-size-30" />
            <div className="col-12">
              <span className="d-block">ul. Główna 4 A</span>
              <span className="d-block">44-144 Nieborowice</span>
              <span>NIP: 969-133-07-32</span>
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {methods.formState.isSubmitted && (
              <Alert variant="success" className="border-medium p-3">
                Dziękujemy za kontakt. Odpowiemy na Twoje pytanie tak szybko, jak to możliwe.
              </Alert>
            )}
            {!methods.formState.isSubmitted && (
              <>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Input name="name" placeholder="Imię i nazwisko" className="col-12" />
                    <Input name="phone" placeholder="Nr telefonu" className="col-12" />
                    <Input name="email" placeholder="Adres e-mail" className="col-12" />
                    <Input name="subject" placeholder="Temat pytania" className="col-12" />
                  </div>
                  <textarea
                    className="border-small d-block bg-light-gray border-green-light p-3 col-12 col-md-6 mt-5 mt-lg-0"
                    rows={4}
                    placeholder="Treść wiadomości"
                    {...methods.register('message')}
                  />
                </div>
                <div className="col-12 mt-3 text-end">
                  <Button className="border-medium py-3 py-lg-2 px-5" type="submit" disabled={isLoading}>
                    Wyślij
                  </Button>
                </div>
              </>
            )}
          </form>
        </FormProvider>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2552.0436840961!2d18.606596500000002!3d50.23508880000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471130ffed4a0701%3A0xec130fa271903026!2sDobreNasiona!5e0!3m2!1spl!2spl!4v1728825697442!5m2!1spl!2spl"
          width="100%"
          height="260"
          className="border-medium mt-5"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </PageLayout>
  )
}
